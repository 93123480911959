import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class About extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        {/* <!-- banner --> */}
        <section class="inner-page-banner" id="home"></section>
        {/* <!-- //banner -->
<!-- page details --> */}
        <div class="breadcrumb-agile">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <Link to="/">{t("about.init")}</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {t("about.title")}
            </li>
          </ol>
        </div>
        {/* <!-- //page details -->
	<!--about-mid --> */}
        <section class="banner-bottom py-5" id="exp">
          <div class="container py-md-5">
            <h3 class="heading text-center mb-3 mb-sm-5">
              {" "}
              {t("about.title")}
            </h3>
            <div class="row mid-grids mt-lg-5 mt-3">
              <div class="col-md-5 content-w3pvt-img">
                <img src="assets/images/ab1.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-md-7 content-left-bottom entry-w3ls-info text-left mt-3">
                <h4> {t("about.greet")}</h4>
                <h5> {t("about.subGreet")}</h5>
                <p class="mt-2 text-left">{t("about.message")}</p>
              </div>
            </div>
          </div>
        </section>

        <section class="banner-bottom py-5">
          <div class="container py-md-5">
            <h3 class="heading text-center mb-3 mb-sm-5"> {t("about.team")}</h3>
            <div class="row justify-content-center mt-lg-5 mt-4">
              <div class="col-md-4 team-gd text-center">
                <div class="team-img mb-4">
                  <img
                    src="assets/images/t1.jpg"
                    class="img-fluid"
                    alt="user-kuva"
                  />
                </div>
                <div class="team-info">
                  <h3 class="mt-md-4 mt-3">HAUZIR OMAR</h3>
                </div>
              </div>

              <div class="col-md-4 team-gd second text-center my-md-0 my-5">
                <div class="team-img mb-4">
                  <img
                    src="assets/images/t2.jpg"
                    class="img-fluid"
                    alt="user-kuva"
                  />
                </div>
                <div class="team-info">
                  <h3 class="mt-md-4 mt-3 text-uppercase">Dastan Salahaddin</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withTranslation("global")(About);
