import React, { Component } from "react";
import {
  DEV,
  URL_KKN_JOENSUU_FACEBOOK,
  URL_KKN_JOENSUU_INSTAGRAM,
} from "../url";
import { withTranslation } from "react-i18next";

class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <footer class="footer-content">
        <div class="layer footer">
          <div class="container-fluid">
            <div class="row footer-top-inner-w3ls">
              <div class="col-lg-4 col-md-6 footer-top ">
                <h2>
                  <a href="index.html">Parturi KKN Joensuu</a>
                </h2>
                <p class="my-3">{t("footer.message")}</p>
              </div>
              <div class="col-lg-4 col-md-6 mt-md-0 mt-5">
                <div class="footer-w3pvt">
                  <h3 class="mb-3 w3pvt_title">{t("footer.hours")}</h3>
                  <hr />
                  <ul class="list-info-w3pvt last-w3ls-contact mt-lg-4">
                    <li>
                      <p> {t("footer.time")}</p>
                    </li>
                    <li class="my-2">
                      <p>{t("footer.time_two")}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mt-lg-0 mt-5">
                <div class="footer-w3pvt">
                  <h3 class="mb-3 w3pvt_title">{t("footer.contact")}</h3>
                  <hr />
                  <div class="last-w3ls-contact">
                    {/* <p>
                                    <a href="mailto:example@email.com">info@example.com</a>
                                </p> */}
                  </div>
                  <div class="last-w3ls-contact my-2">
                    <p>{t("footer.phone")}</p>
                  </div>
                  <div class="last-w3ls-contact">
                    <p>
                      Torikatu 23
                      <br />
                      80100 Joensuu, Finland
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p class="copy-right-grids text-li text-center my-sm-4 my-4">
              {t("footer.final")}
              <a href={DEV}> noSweatSuperDry </a>
            </p>
            <div class="w3ls-footer text-center mt-4">
              <ul class="list-unstyled w3ls-icons">
                <li>
                  <a href={URL_KKN_JOENSUU_FACEBOOK}>
                    <span class="fa fa-facebook-f"></span>
                  </a>
                </li>
                <li>
                  <a href={URL_KKN_JOENSUU_INSTAGRAM}>
                    <span class="fa fa-instagram"></span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="move-top text-right">
              <a href="#home" class="move-top">
                {" "}
                <span class="fa fa-angle-up  mb-3" aria-hidden="true"></span>
              </a>
            </div>
          </div>
          {/* <!-- //footer bottom --> */}
        </div>
      </footer>
    );
  }
}
export default withTranslation("global")(Footer);
