import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class LanguageSwitch extends Component {
  render() {
    const checkCurrennt = this.props.i18n.language;
    const handleChangeLanguage = (language) => {
      this.props.i18n.changeLanguage(language);
    };
    return (
      <div>
        {checkCurrennt === "en" ? (
          <li class="mr-lg-3 mr-2">
            <Link to="/" onClick={() => handleChangeLanguage("fi")}>in Finnish</Link>
          </li>
        ) : (
          <div> </div>
        )}
        {checkCurrennt === "fi" ? (
          <li class="mr-lg-3 mr-2">
            <Link to="/" onClick={() => handleChangeLanguage("en")}>in English</Link>
          </li>
        ) : (
          <div> </div>
        )}
      </div>
    );
  }
}
export default withTranslation("global")(LanguageSwitch);
