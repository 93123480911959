import React, { Component } from "react";
import Pricing from "./components/Price/index";
import AboutUsHome from "./components/AboutUsHome/index";
import ServicesHome from "./components/SerrvicesHome/index";
import Review from "./components/Review/index";
import { URL_KKN_JOENSUU } from "../url/index";
import { withTranslation } from "react-i18next";
import First from "./components/Splash/First";
import Second from "./components/Splash/Second";
import Third from "./components/Splash/Third";
import Fourth from "./components/Splash/Fourth";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenWeekTime: false,
      isOpenWeekendTime: false,
      isShopDay: false,
      currentSlide: 0
    };
  }

  componentDidMount() {
    this.setShopStatus();
    this.interval = setInterval(this.nextSlide, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setShopStatus = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const today = new Date();
    const shopDay = today.toLocaleString("default", { weekday: "long" });

    this.setState({
      isOpenWeekTime:
        hours >= 9 && (hours < 19 || (hours === 19 && minutes === 0)),
      isOpenWeekendTime:
        hours >= 9 && (hours < 19 || (hours === 19 && minutes === 0)),
      isShopDay:
        shopDay === "Monday" ||
        shopDay === "Tuesday" ||
        shopDay === "Wednesday" ||
        shopDay === "Thursday" ||
        shopDay === "Friday" ||
        shopDay === "Saturday",
    });
  };

  nextSlide = () => {
    const { currentSlide } = this.state;
    const totalSlides = 4; // Assuming there are 4 slides
    this.setState({
      currentSlide: (currentSlide + 1) % totalSlides
    });
  }

  render() {
    const { isOpenWeekTime, isOpenWeekendTime, isShopDay, currentSlide } = this.state;
    return (
      <div>
        <div className="banner_w3lspvt" id="home">
          <div className="csslider infinity" id="slider1">
            <input type="radio" name="slides" checked={currentSlide === 0} id="slides_1" />
            <input type="radio" name="slides" checked={currentSlide === 1} id="slides_2" />
            <input type="radio" name="slides" checked={currentSlide === 2} id="slides_3" />
            <input type="radio" name="slides" checked={currentSlide === 3} id="slides_4" />
            <ul className="banner_slide_bg">
              <li>
                <First
                  isShopDay={isShopDay}
                  isOpenWeekTime={isOpenWeekTime}
                  isOpenWeekendTime={isOpenWeekendTime}
                  URL_KKN_JOENSUU={URL_KKN_JOENSUU}
                />
              </li>
              <li>
                <Second
                  isShopDay={isShopDay}
                  isOpenWeekTime={isOpenWeekTime}
                  isOpenWeekendTime={isOpenWeekendTime}
                  URL_KKN_JOENSUU={URL_KKN_JOENSUU}
                />
              </li>
              <li>
                <Third
                  isShopDay={isShopDay}
                  isOpenWeekTime={isOpenWeekTime}
                  isOpenWeekendTime={isOpenWeekendTime}
                  URL_KKN_JOENSUU={URL_KKN_JOENSUU}
                />
              </li>
              <li>
                <Fourth
                  isShopDay={isShopDay}
                  isOpenWeekTime={isOpenWeekTime}
                  isOpenWeekendTime={isOpenWeekendTime}
                  URL_KKN_JOENSUU={URL_KKN_JOENSUU}
                />
              </li>
            </ul>
            <div className="navigation">
              <div>
                <label htmlFor="slides_1"></label>
                <label htmlFor="slides_2"></label>
                <label htmlFor="slides_3"></label>
                <label htmlFor="slides_4"></label>
              </div>
            </div>
          </div>
        </div>
        <Pricing />
        <ServicesHome />
        <AboutUsHome />
        <Review />
      </div>
    );
  }
}
export default withTranslation("global")(Home);
