import React, { Component } from "react";
import { URL_KKN_JOENSUU } from "../../../url/index";
import { withTranslation } from "react-i18next";

class AboutUsHome extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <section class="content-info py-5" id="about">
          <div class="container py-md-5">
            <h3 class="heading text-center mb-3 mb-sm-5">
              {t("aboutH.title")}
            </h3>

            <div class="info-w3pvt-mid text-center px-lg-5">
              <div class="title-desc text-center px-lg-5">
                <img
                  src="assets/images/about1.png"
                  alt="news kuva"
                  class="img-fluid"
                />
                <p class="px-lg-5">{t("aboutH.description")}</p>
                <span />
                <br />
                <a href={URL_KKN_JOENSUU} class="btn">
                  {t("aboutH.appointment")}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("global")(AboutUsHome);
