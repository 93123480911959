import React, { Component } from "react";
import { Link } from "react-router-dom";
import { URL_KKN_JOENSUU } from "../../../url/index";
import { withTranslation } from "react-i18next";

class ServicesHome extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <section class="services py-5" id="services">
          <div class="container py-md-5">
            <h3 class="heading text-center mb-3 mb-sm-5">
              {t("servicesH.title")}
            </h3>
            <div class="row ab-info">
              <div class="col-md-6 ab-content ab-content1">
                <div class="ab-content-inner">
                  <Link to="/Gallery">
                    <img
                      src="assets/images/services2.jpg"
                      alt="news kuva"
                      class="img-fluid"
                    />
                  </Link>
                  <div class="ab-info-con">
                    <h4>{t("servicesH.photoa")}</h4>
                    <a
                      href={URL_KKN_JOENSUU}
                      class="read-more two btn m-0 px-3"
                    >
                      <span class="fa fa-arrow-circle-o-right"> </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 ab-content ab-content1">
                <div class="ab-content-inner">
                  <Link to="/Gallery">
                    <img
                      src="assets/images/services1.jpg"
                      alt="news kuva"
                      class="img-fluid"
                    />
                  </Link>
                  <div class="ab-info-con">
                    <h4>{t("servicesH.photob")}</h4>
                    <a
                      href={URL_KKN_JOENSUU}
                      class="read-more two btn m-0 px-3"
                    >
                      <span class="fa fa-arrow-circle-o-right"> </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("global")(ServicesHome);
