import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Pricing extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        {" "}
        <section class="pricing py-5">
          <div class="container py-md-5">
            <h3 class="heading text-capitalize text-center mb-3 mb-sm-5">
              {t("priceH.title")}
            </h3>
            <div class="row pricing-grids">
              <div class="col-lg-6  mb-lg-0 mb-5">
                <div class="padding">
                  <h3> {t("priceH.subtitle")}</h3>
                  {/* <!-- Item starts --> */}
                  <div class="menu-item">
                    <div class="row border-dot no-gutters">
                      <div class="col-8 menu-item-name">
                        <h6> {t("priceH.item_one")}</h6>
                      </div>
                      <div class="col-4 menu-item-price text-right">
                        <h6>€25</h6>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Item ends -->
                <!-- Item starts --> */}
                  <div class="menu-item my-4">
                    <div class="row border-dot no-gutters">
                      <div class="col-8 menu-item-name">
                        <h6>{t("priceH.item_two")}</h6>
                      </div>
                      <div class="col-4 menu-item-price text-right">
                        <h6>€20</h6>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Item ends -->
                //future
              <div class="menu-item">
                <div class="row border-dot no-gutters">
                  <div class="col-8 menu-item-name">
                    <h6>Trim your Mustaches style 3</h6>
                  </div>
                  <div class="col-4 menu-item-price text-right">
                    <h6>$15</h6>
                  </div>
                </div>
              </div>
              
                <!-- Item starts --> */}
                </div>
              </div>
              <div class="col-lg-6  mb-lg-0 mb-5">
                <div class="padding">
                  <h3>{t("priceH.subtittledisc")}</h3>
                  {/* <!-- Item starts --> */}
                  <div class="menu-item">
                    <div class="row border-dot no-gutters">
                      <div class="col-8 menu-item-name">
                        <h6>{t("priceH.item_three")}</h6>
                      </div>
                      <div class="col-4 menu-item-price text-right">
                        <h6>€20</h6>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Item ends -->
                <!-- Item starts --> */}
                  <div class="menu-item my-4">
                    <div class="row border-dot no-gutters">
                      <div class="col-8 menu-item-name">
                        <h6>{t("priceH.item_four")}</h6>
                      </div>
                      <div class="col-4 menu-item-price text-right">
                        <h6>€23</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("global")(Pricing);
