import React, { Component } from "react";
import { Link } from "react-router-dom";
import { URL_KKN_JOENSUU_GALLERY } from "../url/index";
import { withTranslation } from "react-i18next";
class Gallery extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <section class="inner-page-banner" id="home"></section>

        <div class="breadcrumb-agile">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <Link to="/">{t("gallery.init")}</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {t("gallery.title")}
            </li>
          </ol>
        </div>
        <section class="gallery py-5" id="gallery">
          <div class="container py-md-5">
            <h3 class="heading text-center mb-3 mb-sm-5">
              {t("gallery.title")}
            </h3>
            <div class="gallery-content">
              <iframe
                title="insta"
                src={URL_KKN_JOENSUU_GALLERY}
                width="100%"
                height="1000"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withTranslation("global")(Gallery);
