export const URL_KKN_JOENSUU = "https://slotti.fi/booking/parturikkn/";
export const URL_KKN_JOENSUU_GALLERY =
  "https://widget-899cd98627ef48aabede9fd2d71265e2.elfsig.ht";
export const URL_KKN_JOENSUU_FACEBOOK = "https://www.facebook.com/Parturikkn/";
export const URL_KKN_JOENSUU_INSTAGRAM =
  "https://www.instagram.com/parturi_kkn";
export const URL_REVIEW =
  "https://widget-70ecdfba595e4b22b63b8d1d18fa6062.elfsig.ht";
export const DEV = "https://nosweatsuperdry.onrender.com/";
export const MAP_KKN_JOENSUU =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1836.0221023251509!2d29.763291333020582!3d62.60147887944046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469b87d00dce5199%3A0xc343f4e022610402!2sParturi%20KKN%20Joensuu!5e0!3m2!1sen!2sfi!4v1690905766226!5m2!1sen!2sfi";
