import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
class Third extends Component {
  render() {
    const { t, isShopDay, isOpenWeekTime, isOpenWeekendTime, URL_KKN_JOENSUU } = this.props
    return (

                <div class="slider-info bg3">
                  <div class="bs-slider-overlay1">
                    <div class="banner-text">
                      <div class="container">
                        <h2 class="movetxt agile-title text-capitalize">
                          {t("home.welcome_three")}
                        </h2>
                        <h3 class="tittle order">
                          <span>{t("home.message_one")}</span>
                          {t("home.message_two")}
                        </h3>
                        <h4 class="tittle my-2">0468456395 </h4>

                        {isShopDay ? (
                          <div>
                            {isOpenWeekTime ? (
                              <a href={URL_KKN_JOENSUU} class="btng ">
                                {t("home.open")}
                              </a>
                            ) : (
                              <a href={URL_KKN_JOENSUU} class="btnr">
                                {t("home.closed")}
                              </a>
                            )}
                          </div>
                        ) : (
                          <div>
                            {isOpenWeekendTime ? (
                              <a href={URL_KKN_JOENSUU} class="btng">
                                {t("home.open")}
                              </a>
                            ) : (
                              <a href={URL_KKN_JOENSUU} class="btnr">
                                {t("home.closed")}
                              </a>
                            )}
                          </div>
                        )}

                        <a href={URL_KKN_JOENSUU} class="btn">
                          {t("home.appointment")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

    )
  }
}

export default withTranslation('global')(Third)