import React, { Component } from "react";
import { URL_REVIEW } from "../../../url/index";
import { withTranslation } from "react-i18next";

class Review extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        {" "}
        <section class="services py-5" id="services">
          <div class="container py-md-5">
            <h3 class="heading text-center mb-3 mb-sm-5">
              {t("reviewH.title")}
            </h3>{" "}
            <iframe
              title="review"
              src={URL_REVIEW}
              width="100%"
              height="300"
              frameborder="0"
            ></iframe>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("global")(Review);
