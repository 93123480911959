import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MAP_KKN_JOENSUU } from "../url";
class Contact extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <section class="inner-page-banner" id="home"></section>
        <div class="breadcrumb-agile">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <Link to="/">{t("contact.init")}</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {t("contact.title")}
            </li>
          </ol>
        </div>
        <section class="content-info py-5">
          <div class="container py-md-5">
            <div class="text-center px-lg-5">
              <h3 class="heading text-center mb-3 mb-sm-5">
                {t("contact.title")}
              </h3>
              <div class="title-desc text-center px-lg-5">
                <p class="px-lg-5 sub-wthree">{t("contact.message")}</p>
                <p class="px-lg-5 sub-wthree">{t("contact.message1")}</p>
                <p class="font-weight-bold">0468456395</p>
                <p class="px-lg-5 sub-wthree"> {t("contact.message2")}</p>
              </div>
              <span />
              <p class="px-lg-5 sub-wthree ">{t("contact.smallMessage")}</p>
              <p class="px-lg-5 sub-wthree font-weight-bold">
                Torikatu 23, Joensuu 80100, Joensuu
              </p>
            </div>
          </div>
        </section>

        <div class="map-w3layouts ">
          <iframe
            title="map-kkn"
            src={MAP_KKN_JOENSUU}
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
    );
  }
}
export default withTranslation("global")(Contact);
