import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { URL_KKN_JOENSUU_FACEBOOK, URL_KKN_JOENSUU_INSTAGRAM } from "../url/index";
import LanguageSwitch from "./LanguageSwitch";
class Navbar extends Component {
  render() {
    const { t } = this.props;
    return (
      <header>
        <div class="container">
          <div class="header d-lg-flex justify-content-between align-items-center">
            <div class="header-agile">
              <h1>
                <Link to="/" class="navbar-brand logo">
                  <span class="fa fa-scissors" aria-hidden="true"></span>{" "}
                  Parturi KKN Joensuu<p className="text-white">Torikatu 23, 80100 Joensuu</p>
                </Link>
              </h1>
            </div>
            <div style={{flexDirection:"row"}}>
            
            <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href={URL_KKN_JOENSUU_FACEBOOK} >
                      &nbsp; <i className="fa fa-facebook-official" style={{color: "#ffc905"}}></i>
                      </a>
                    </li>&nbsp;&nbsp;
                    <li className="list-inline-item">
                      <a href={URL_KKN_JOENSUU_INSTAGRAM} >
                        <i className="fa fa-instagram" style={{color: "#ffc905"}}></i>
                      </a>
                    </li>
                
                  </ul>
                  </div>
                 
            <div class="nav_w3ls">
              <nav>
                <label for="drop" class="toggle mt-lg-0 mt-1 row" style={{backgroundColor: "#ffc905"}}>
                  <p style={{fontSize:"14px", color:"black"}}>{t("nav.menu")}<span class="fa fa-bars" aria-hidden="true"></span></p>
                  
                </label>
                <input type="checkbox" id="drop" />
                <ul class="menu row">
                  <li class="mr-lg-3 mr-2 active">
                    <Link to="/">{t("nav.home")}</Link>
                  </li>
                  <li class="mr-lg-3 mr-2 active">
                    <Link to="/appointment">{t("nav.appointment")}</Link>
                  </li>
                  <li class="mr-lg-3 mr-2">
                    <Link to="/gallery">{t("nav.gallery")}</Link>
                  </li>
                  <li class="mr-lg-3 mr-2">
                    <Link to="/about">{t("nav.about")}</Link>
                  </li>
                  <li class="mr-lg-3 mr-2">
                    <Link to="/contact">{t("nav.contact")}</Link>
                  </li>
                  <LanguageSwitch/>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default withTranslation("global")(Navbar);
